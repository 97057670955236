<template>
  <div class="body">
    <article class="banner">
      <div class="layout">
        <div class="summary">
          <h2><svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 86" width="320" height="86">
            <g fill="#fff">
              <path d="M35.327 41.209l2.968-10.545h-8.098l1.462-13.45h3.918l2.968-10.545h-5.739L33.531 0H22.876l-.804 6.669H11.568L9.497 17.214h11.302l-.164 1.357H11.93L0 75.367h10.7l7.243-34.485L12.499 86l12.196-4.741 4.355-40.05h6.277z" />
              <path d="M80.258 60.519H69.466l-1.571 4.303H55.589l-.658-4.303H44.253l.658 4.303H28.47l-1.12 10.545h65.36l1.12-10.545H78.686l1.572-4.303z" />
              <path d="M118.166 1.931h-10.867L90.737 38.044h10.311l-6.21 47.655 12.117-2.479 6.544-59.01h-5.55l10.217-22.279zm29.792 32.824h21.655l1.12-10.545h-21.655l2.41-22.692-11.978 3.501-1.769 19.191h-20.983l-1.119 10.545h21.129l-2.772 30.067h-17.054l-1.12 10.545h47.203l1.12-10.545h-19.379l3.192-30.067zM59.467 22.959l-4.231 10.545h17.739l1.574-3.154.744 3.154h11.146l-1.475-6.254h5.746l2.969-10.545H82.475l-.41-1.738h14.358l2.969-10.545H79.577l-.588-2.491H67.843l4.962 21.028H59.467zM49.72 12.476l-.865 1.953H38.649L35.68 24.974h8.509l-3.747 8.467h12.431L63.46 10.369l-2.769-8.438H41.445l-2.968 10.545H49.72zm33.823 46.64l4.164-24.001H41.008l-4.164 24.001h32.235l2.959-10.545H50.182l.309-2.911h25.201l-3 13.456h10.851zm106.388-45.169L185.841 0h-11.674l4.146 13.947h11.618zm-15.203 2.988l4.146 13.947h11.618l-4.09-13.947h-11.674zm4.178 19.396l-13.585 39.567h11.674l13.585-39.567h-11.674zm61.367-.164h-46.136L190.555 46.6h35.233l-7.027 28.771h21.872l2.497-10.223h-9.936l7.079-28.981zm78.619-19.31L320 6.424h-42.366l2.939-5.059h-11.936l-2.939 5.059h-13.533l-2.548 10.433h10.018l-15.386 26.476h33.66l-1.114 10.49h-30.326l-2.548 10.433h31.766l-1.18 11.115h11.506l1.18-11.115h26.666l1.108-10.433h-26.666l1.114-10.49h25.494l1.085-10.222h-25.493l1.241-11.688-11.875 3.471-.873 8.217h-16.869l9.446-16.254h47.321zM248.316 6.424h-37.793l1.651-3.689h-12.43l-6.454 14.122h52.478l2.548-10.433zm-10.835 25.26l2.549-10.433h-40.587l-3.582 10.433h41.62z" />
            </g>
          </svg>合伙人，把握商机</h2>
          <p>更多使用场景等你去发现</p>
        </div>
        <img class="img" src="../../assets/v4/partner/banner.png" width="1920" height="680" />
      </div>
    </article>

    <article class="scenes">
      <div class="layout">
        <div class="summary">
          <h2>众多应用场景 <br class="mShow" />快速切入蓝海市场</h2>
          <p>橙仕汽车已在北京、山东、江苏、山西、广东、安徽、河北、四川、重庆、浙江等省市投入使用</p>
          <a class="btnTel" href="tel:4007991212">加盟热线 <strong>400-799-1212</strong></a>
        </div>
        <div class="imgWrap">
          <img class="img" src="../../assets/v4/partner/scenes.png" width="1280" height="680" />
        </div>
      </div>
    </article>

    <article class="box">
      <div class="layout">
        <h2>合作支持 <strong>山东豪驰智能汽车有限公司</strong></h2>
        <ul class="props">
          <li>试乘试驾</li>
          <li><strong>营销支持</strong></li>
          <li>运营支持</li>
          <li>培训支持</li>
          <li>品牌形象</li>
          <li>活动物料</li>
          <li><strong>市场拓展</strong></li>
          <li>售后服务</li>
        </ul>
        <p class="t"><svg class="svg" xmlns="http://www.w3.org/2000/svg" width="170" height="86" viewBox="0 0 100 50">
          <g fill="currentColor">
            <path d="M21.33 23.958l1.708-6.13h-4.661l.842-7.82h2.255l1.708-6.13h-3.303L20.296 0h-6.131l-.463 3.878H7.657l-1.192 6.13h6.504l-.094.789h-5.01L1 43.818h6.158l4.168-20.049L8.193 50l7.019-2.756 2.506-23.286h3.612z"/>
            <path d="M47.186 35.185h-6.21l-.905 2.502H32.99l-.379-2.502h-6.145l.379 2.502h-9.461l-.645 6.131h37.613l.645-6.131h-8.715l.904-2.502z"/>
            <path d="M69.001 1.123h-6.254l-9.53 20.995h5.933l-3.573 27.707 6.973-1.442 3.765-34.307h-3.193l5.879-12.953zM98.607 20.207l.645-6.131H86.79L88.177.882l-6.893 2.036-1.018 11.158H68.191l-.644 6.131h12.159l-1.595 17.48h-9.814l-.645 6.131h27.164l.645-6.131H84.309l1.837-17.48h12.461zM35.222 13.348l-2.435 6.131h10.208l.906-1.834.428 1.834h6.414l-.849-3.636h3.307l1.708-6.131h-6.447l-.236-1.01h8.263l1.708-6.131H46.794l-.338-1.448h-6.414l2.855 12.225h-7.675zM29.612 7.253l-.497 1.136h-5.874l-1.708 6.131h4.896l-2.156 4.922h7.154L37.52 6.029l-1.594-4.906H24.85l-1.708 6.13h6.47zM49.077 34.37l2.396-13.954H24.599L22.203 34.37h18.55l1.703-6.131H29.878l.178-1.692h14.503l-1.727 7.823h6.245z"/>
          </g>
        </svg>新能源<span class="mHide">，</span><br class="mShow">全新末端配送方式</p>
        <div class="imgWrap">
          <img class="img" src="../../assets/v4/partner/cars.jpg" width="1080" height="460" />
        </div>
        <a class="btnTel" href="tel:4007991212">加盟热线 <strong>400-799-1212</strong></a>

        <h2>应用范围</h2>
        <ul class="props">
          <li>快递物流</li>
          <li>商超配送</li>
          <li>乡村振兴</li>
          <li>鲜花配送</li>
          <li>商贩经营</li>
          <li>城市巡逻</li>
          <li>消防救援</li>
          <li>城市清扫</li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "Partner"
};
</script>

<style scoped lang="scss">
.btnTel {
  display: inline-block;
  vertical-align: top;
  padding: 0 52px;
  font-size: 38px;
  line-height: 80px;
  color: #FF3F00;
  border: 2px solid #FF3F00;
  border-radius: 100px;

  strong {
    display: inline-block;
    vertical-align: top;
    font-size: 52px;
    font-weight: 900;
  }
}

.imgWrap {
  margin: 0 auto;

  .img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.banner {
  padding-top: 300px;
  background-color: #e83e0b;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary {
    position: relative;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: #fff;

    h2 {
      margin-bottom: 30px;
      font-size: 76px;
      line-height: 86px;
      color: #fff;

      .svg {
        vertical-align: top;
        margin-right: 8px;
      }
    }
  }

  .img {
    display: block;
    margin-top: -8%;
    margin-bottom: -10.416667%;
    max-width: 100%;
    height: auto;
    background: radial-gradient(ellipse 50% 50% at 50% 50%, rgba(255, 102, 0, 1) 33%, rgba(255, 80, 0, 0) 100%);
  }
}

.scenes {
  padding-top: 280px;
  background-color: #fbf1ec;

  .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .summary {
    position: relative;
    margin-bottom: #{"min(-13.54%, -160px)"};
    max-width: 800px;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    h2 {
      margin-bottom: 20px;
      font-size: 52px;
      line-height: 62px;
      font-weight: bold;
    }

    .btnTel {
      margin-top: 40px;
    }
  }

  .imgWrap {
    max-width: 1280px;

    .img {
      transform: translateY(50%);
    }
  }
}

.box {
  padding-top: #{"min(21.36%, 410px)"};
  padding-bottom: 80px;
  text-align: center;

  h2 {
    margin-bottom: 28px;
    font-size: 52px;
    line-height: 62px;
  }

  .props {
    display: flex;
    margin: 0 auto 72px;
    max-width: 800px;
    flex-wrap: wrap;
    font-size: 32px;
    line-height: 56px;
    overflow: hidden;

    li {
      margin-left: -1px;
      flex: 1;
      position: relative;
      text-align: center;
      min-width: 22%;

      &::before {
        position: absolute;
        top: 12px;
        left: 0;
        height: 32px;
        width: 1px;
        overflow: hidden;
        content: '';
        background-color: currentColor;
      }
    }
  }

  .t {
    margin-bottom: 30px;
    font-size: 76px;
    line-height: 86px;

    .svg {
      vertical-align: top;
    }
  }

  .imgWrap {
    margin-bottom: 100px;
    max-width: 1080px;
  }

  .btnTel {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .btnTel {
    padding: 0 24px;
    font-size: 18px;
    line-height: 40px;

    strong {
      font-size: 24px;
    }
  }

  .banner {
    padding-top: 160px;

    .layout {
      padding: 0;
    }

    .summary {
      font-size: 16px;
      line-height: 26px;

      h2 {
        margin-bottom: 8px;
        font-size: 40px;
        line-height: 50px;
        text-align: center;

        .svg {
          display: block;
          width: 100%;
          height: 46px;
        }
      }
    }

    .img {
      margin-top: 8%;
      margin-bottom: 0;
    }
  }

  .scenes {
    padding-top: 60px;

    .summary {
      margin-bottom: -50px;
      max-width: 90%;
      font-size: 14px;
      line-height: 20px;

      h2 {
        margin-bottom: 16px;
        font-size: 28px;
        line-height: 34px;
      }
    }

    .imgWrap {
      max-width: 1280px;

      .img {
        transform: translateY(50%);
      }
    }
  }

  .box {
    padding-top: 120px;
    padding-bottom: 40px;

    h2 {
      margin-bottom: 16px;
      font-size: 28px;
      line-height: 34px;

      strong {
        display: block;
      }
    }

    .props {
      margin-bottom: 48px;
      font-size: 14px;
      line-height: 26px;
      overflow: hidden;

      li {
        &::before {
          top: 6px;
          height: 14px;
        }
      }
    }

    .t {
      margin-bottom: 16px;
      font-size: 36px;
      line-height: 40px;

      .svg {
        width: 82px;
        height: 40px;
      }
    }

    .imgWrap {
      margin-bottom: 60px;
    }

    .btnTel {
      margin-bottom: 30px;
    }
  }
}
</style>
